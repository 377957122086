.profileTop{ 
	margin-bottom:20px; 
} 
.profileTop h1{ 
	display:inline-block; 
	vertical-align:middle; 
	margin-bottom:0px !important; 
	margin-right:20px; 
} 
.typebadge{ 
	text-transform:uppercase; 
	display:inline-block; 
	border-radius:5px; 
	padding:2px 5px;
	font-size:0.85rem;
	letter-spacing:1px; 
	font-weight:bold; 
	vertical-align:middle; 
	margin:0px 10px; 
	color:white; 
	  box-shadow: 0 1px 3px rgba(0,0,0,0.2), 0 1px 2px rgba(0,0,0,0.44);
} 
.typebadge.volunteer{
	background-color:#0d9348; 
} 
.typebadge.director{
	background-color:#b82a34; 
} 
.typebadge.job{
	background-color:#28377d; 
} 


.personal-details svg{ 
	margin-right:10px; 
} 
.personal-details .profile-bit{ 
	padding-bottom:10px; 
} 
.guestBio{ 
	padding-top:15px; 
} 


.guestProfileConnect .col,
.guestProfileConnect .col-sm-4{ 
	padding-bottom:10px; 
} 
.social-links .col-sm-6{ 
	padding-bottom:10px; 
} 
.guestProfileConnect svg{ 
	margin-right:10px; 
} 

.guestPhotosScroll .carousel-inner{ 
	width:400px;
	margin:0 auto; 
} 
.guestPhotosScroll .carousel-control-next,
.guestPhotosScroll .carousel-control-prev{
	color:#000000; 
} 
#photoCarousel .card-body{ 
	padding:2px; 
} 
#photoCarousel .carousel-caption{ 
	right:2.5%; 
	left:2.5%; 
	bottom:15px;
	padding-top:10px; 
	padding-bottom:10px; 
	background-color:rgba(0,0,0,.5); 
	font-size:0.9rem; 
} 

.newMessage .tox-statusbar__branding{ 
	display:none; 
} 




@media (max-width: 576px) { 

	.profileTop h1{ 
		display:block; 
		margin-bottom:10px !important; 
		margin-right:0px; 
	} 
	
	.profileTop .typebadge:first-of-type{ 
		margin-left:0px; 
	} 

	.guestPhotosScroll .carousel-inner{ 
		width:300px;
		margin:0 auto; 
	} 
}