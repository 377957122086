.nav-tabs{ 
	border-bottom:none; 
	margin-bottom:30px; 
} 
.nav-tabs .nav-link{ 
	background:transparent; 
	border:1px solid #0d9348; 
	color:#0d9348; 
	border-radius:10px; 
	margin-right:1rem; 
} 

.nav-tabs .nav-link:hover{ 
	border-color: #0d9348; 
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	
}
.nav-tabs .nav-link.active{ 
	background-color:#0d9348; 
	color:#FFFFFF; 
	border:0px; 
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
} 


input.socialMediaEntryField::placeholder{
  font-style:italic; 
}
input.socialMediaEntryField::-ms-input-placeholder{
  font-style:italic; 
}
input.socialMediaEntryField:-ms-input-placeholder{
  font-style:italic; 
}
input.socialMediaEntryField::-webkit-input-placeholder {
  font-style:italic; 
}
#ProfileEditBio .tox-tinymce{ 
	margin-bottom:0px; 
	border:0px; 
} 
#ProfileEditBio .tox-statusbar__branding{ 
	display:none; 
} 
#ProfileEditBio .tox-statusbar__resize-handle{ 
	padding-right:2px; 
	padding-bottom:2px; 
}
#ProfileEditBio .tox-statusbar{ 
	background:transparent; 
} 
#ProfileEditBio .tox-toolbar__primary{ 
	background:transparent; 
} 
#AddFirstProfilePhoto img.profilephoto{ 
	margin-top:10px; 
	margin-bottom:10px; 
}
#ProfilePhotos div.container{ 
	min-height:250px; 
} 
#AddFirstProfilePhoto p{ 
	margin-top:75px; 
} 
button svg{ 
	margin-right:10px; 
} 

#DisplayProfilePhotos{ 
	width:440px; 
	margin:0 auto; 
} 
#DisplayProfilePhotos div.profilephoto:first-of-type{ 
	width:270px; 
	height:270px; 
} 
#DisplayProfilePhotos div.profilephoto:hover .edit-photo-button{ 
	opacity:1; 
}  
#LoadingPhotos{ 
	text-align:center; 
} 
div.col-align-right{ 
	text-align:right; 
} 
.form-cards .row > div:first-of-type{ 
	padding-left:0px; 
}
.form-cards .row > div:last-of-type{ 
	padding-right:0px; 
}
.rook-checkbox{ 
	width:25%; 
	float:left; 
} 
.rook-checkbox:nth-child(2),
.rook-checkbox:nth-child(5){ 
	width:40%; 
}

@media (max-width: 767px) { 

.nav-tabs .nav-link{ 
	margin-bottom:1rem;
	} 
.form-cards .row > div:first-of-type{ 
	padding-left:0px;
	padding-right:0px; 
}
.form-cards .row > div:last-of-type{ 
	padding-left:0px;
	padding-right:0px; 
}
	#ProfilePreferences .preferenceCheckbox label.form-label{ 
		display:block; 
		width:100%; 
	} 
	
	
#DisplayProfilePhotos{ 
	width:300px; 
	margin:0 auto; 
} 
#DisplayProfilePhotos div.profilephoto:first-of-type{ 
	width:125px; 
	height:125px; 
} 
	.narrow-body .card{ 
		width:100% !important; 
	} 
}
@media (min-width: 768px) and (max-width: 991px) {  
.form-cards .row > div:first-of-type{ 
	padding-left:0px;
	padding-right:0px; 
}
.form-cards .row > div:last-of-type{ 
	padding-left:0px;
	padding-right:0px; 
}
	.narrow-body .card{ 
		width:100% !important; 
	} 
}