#Mast{ 
	position:relative; 
	background-image:url(../assets/LoginMastBG.png); 
	background-repeat:no-repeat; 
	background-position:center top;
	background-size:100%; 
	width:100%; 
	height:287px; 
	
} 
#Mast img.frontimg{ 
	position:absolute; 
	z-index:20; 
	top:0; 
  left: 50%;
  transform: translate(-50%);
	width:900px; 
	height:168px; 
	margin-top:20px;
} 
#Mast h1{
	font-family: 'Oswald', sans-serif;
	background-color:#28377D; 
	color:white; 
	text-transform:uppercase; 
	text-align:center;
	position:absolute; 
	z-index:10; 
	display:block; 
	border-top:2px solid black; 
	padding-top:1em;
	margin:0px; 
	width:100%; 
	top:156px;
	height:130px;
	  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	border-bottom-left-radius:20px; 
	border-bottom-right-radius:20px; 
	text-shadow:none;
}
@media (max-width: 394px) { 
	#Mast { 
		height:212px;
	background-size:200%; 
	}
	#Mast img.frontimg{ 
		width:350px; 
		height:65px;
	} 

	#Mast h1{
		font-size:2rem;
		top:72px; 
		height:140px
	}
 }

@media (min-width: 395px) and (max-width: 481px) { 
	#Mast { 
		height:200px;
		background-size:200%; 
	}
	#Mast img.frontimg{ 
		width:400px; 
		height:75px;
	} 

	#Mast h1{
		font-size:1.7rem;
		padding-left:15%; 
		padding-right:15%; 
		top:80px; 
		height:120px
	}
	
}
@media (min-width: 482px) and (max-width: 699px) { 
	#Mast { 
		height: 186px; 
		background-size:160%; 
	}
	#Mast img.frontimg{ 
		width:500px; 
		height:93px;
	} 

	#Mast h1{
		font-size:1.6rem;
		top:96px; 
		height:90px
	}
} 
@media (min-width: 700px) and (max-width: 900px) { 
	#Mast { 
		height: 232px; 
		background-size:160%;
	}
	#Mast img.frontimg{ 
		width:750px; 
		height:139px;
	} 

	#Mast h1{
		font-size:1.85rem;
		top:132px; 
		height:100px
	}
}
@media (min-width: 901px) and (max-width: 1024px) { 
	#Mast { 
		background-size:130%; 
	}
} 
/* .navbar.center .navbar-inner {
    text-align: center;
}

.navbar.center .navbar-inner .nav {
    display:inline-block;
    float: none;
}
 */
.navbar.navbar-light .navbar-toggler{ 
	font-family: 'Oswald', sans-serif;
	color:#FFFFFF; 
	border-color:white; 
}
.navbar.navbar-light .nav-pills .nav-link{ 
	color:#FFFFFF; 
} 
.navbar-collapse:not(.show) .nav-pills>li+li {
  margin-left: 10px; 
} 
.navbar-collapse.show .nav-pills>li+li,
.navbar-collapse.collapsing .nav-pills>li+li {
  margin-left: 0px; 
} 
.navbar-collapse.show .nav-pills>li>a, 
.navbar-collapse.collapsing .nav-pills>li>a{ 
	padding-left:2px; 
} 
.navbar-collapse.collapse{ 
	margin-left:200px; 
} 
header{ 
	position:relative; 
	width:100%; 
	height:185px; 
	z-index:1;  
} 
header img{ 
	position:relative; 
	height:151px;
	width: 200px; 
	z-index:50; 
	margin-top:10px; 
	margin-left:5px; 
} 
header h2{
	font-family: 'Oswald', sans-serif;
	background-color:#28377D; 
	color:white; 
	text-transform:uppercase; 
	text-align:left;
	position:absolute; 
	z-index:10; 
	display:block; 
	margin:0px; 
	font-size:1.5rem;
	padding-top:10px;
	padding-bottom:10px;
	padding-left:225px; 
	margin-left:10px; 
	width:99%;
	top:40px;
	border-top-right-radius:20px; 
}
	
nav.navbar{ 
	display:block; 
	position:absolute; 
	background-color:#692C90; 
	z-index:5; 
	top:88px;
	width:100%;
	margin-left:10px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	border-bottom-right-radius:20px; 
}
header button.signoutbutton{ 
	position:absolute; 
	top:5px; 
	right:1%; 
	padding:5px;
	font-size:.9rem; 
	background-color:#BF2B35;
	border:0px; 
	z-index:25; 
	
}

@media (max-width: 900px) { 
	header{ 
		height:auto; 
		margin-bottom:20px; 
	} 
	header img{
		display:none; 
	}
	header h2{
		position:relative; 
		margin:0px; 
		padding-left:0px; 
		text-align:center; 
		width:100%; 
		top:auto; 
		border-top-right-radius:0px; 
	}
	nav.navbar{ 
		position:relative; 
		top:auto; 
		border-bottom-left-radius:0px; 
		border-bottom-right-radius:0px; 
		margin-left:0px; 
		
	}
	
	.navbar-collapse.collapse{ 
		margin-left:auto; 
		margin-top:10px; 
	}
	.navbar.navbar-light .nav-pills .nav-link{
		padding-left:10px; 
		margin-left:-10px; 
	}
	
	header button.signoutbutton{ 
		top:55px;
		right:15px; 
		background:transparent; 
		border:1px solid white; 
	}
}
/* 
@media (min-width: 395px) and (max-width: 481px) { 
	header img{
		display:none; 
	}
	
}
@media (min-width: 482px) and (max-width: 699px) { 
	header img{
		display:none; 
	}
} 
@media (min-width: 700px) and (max-width: 900px) { 
	header img{
		display:none; 
	}
} */