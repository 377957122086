.recentlyJoinedPerson{ 
	margin-bottom:15px; 
	cursor: pointer;
    cursor: hand;
} 
.recentlyJoinedPerson h6{ 
	font-size:1.5em; 
} 
.recentlyJoinedPerson .profiledetails span{ 
	color:#999999; 
}
.recentlyJoinedPerson .profiledetails span:first-child{ 
	margin-right:25px; 
} 
.recentlyJoinedPerson .profiledetails span:first-child svg{ 
	margin-right:4px; 
} 
.profilePrompts .prompt{ 
	border-bottom:1px solid #CCC; 
} 
.profilePrompts .prompt  .col{
	padding-bottom:10px;
	padding-top:10px; 
}
.profilePrompts .prompt:first-of-type .col{ 
	padding-top:0px; 
} 
.profilePrompts .prompt  .col span{
	display:inline-block; 
}
.profilePrompts .prompt .col span:first-child { 
	width:10%; 
	vertical-align:middle; 
} 
.profilePrompts .prompt  .col span:last-child { 
	width:85%; 
	vertical-align:middle; 
} 

.profilePrompts .prompt:last-of-type{ 
	border-bottom:0px; 
} 
.profilePrompts .prompt:last-of-type .col{ 
	padding-bottom:0px; 
} 
.pileInvitePreamble{ 
	margin-bottom:15px; 
} 
.unreadMessage{ 
	margin-bottom:20px; 
} 
.unreadMessage .envelope{ 
	font-size:0.8em; 
} 
.unreadMessage .subject{ 
	font-size:1.1em; 
	font-weight:bold; 
} 
.unreadMessage .openButton{ 
	text-align:right; 
	margin-left:-5px; 
} 
.unreadMessage .icon{ 
	padding-top:4px; 
	margin-right:5px;
} 
.unreadMessage .icon img.guest{
		border-radius:20px; 
} 

@media (max-width: 576px) { 
	.recentlyJoinedPerson{ 
		margin-bottom:25px; 
	}
	.recentlyJoinedPerson h6{ 
		font-size:1.3em; 
		margin-bottom:4px;
	} 
	.recentlyJoinedPerson .profiledetails span{ 
		display:block; 
		font-size:0.9em; 
	} 
	.profilePrompts .prompt .col span:first-child { 
		width:20%; 
		vertical-align:top; 
		margin-top:10px; 
	} 
	.profilePrompts .prompt  .col span:last-child { 
		width:75%; 
		vertical-align:middle; 
	} 
}
@media (min-width: 768px) and (max-width: 991px) { 
	.recentlyJoinedPerson h6{ 
		font-size:1.3em; 
		margin-bottom:4px;
	} 
	.recentlyJoinedPerson .profiledetails span{ 
		display:block; 
		font-size:0.9em; 
	} 
	.profilePrompts .prompt .col span:first-child { 
		width:20%; 
		vertical-align:top; 
		margin-top:10px; 
	} 
	.profilePrompts .prompt  .col span:last-child { 
		width:75%; 
		vertical-align:middle; 
	} 
	
}