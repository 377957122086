.carousel{ 
	margin-top:5px; 
	margin-bottom:20px; 
	border-radius:10px; 
} 
.carousel .carousel-item img{ 
	border-radius:10px; 
} 
ul.featuresList{ 
	padding-left:20px; 
} 
ul.featuresList li{ 
	margin-bottom:8px; 
} 
.current-pref-indicator{ 
	color:#9239a2 !important; 
	text-align:center; 
} 

#pilemembertooltip.show{ 
	opacity:1; 
} 
#pilemembertooltip > .tooltip-inner {
  background-color: #f0c945;
  color: #000;
  border: 1px solid #f0c945;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
    border-bottom-color: #f0c945;
}
.puppyPileInvitationLine >.row >div:last-of-type{ 
	text-align:right; 
} 
#addToPuppyPileSearcher a.dropdown-item div img{ 
	margin-right:10px; 
} 
.rbt-token img{ 
	margin-right:10px; 
} 
.puppyPileInfo .col { 
	margin-top:10px; 
} 
.learnMoreLink{ 
	padding-top:10px; 
} 
.declined .declinebutton{ 
	display:none; 
} 
.puppyPileName{ 
	padding-top:5px; 
} 
.puppyPilePrefs{
	font-size:13px;
	border:1px solid #f0c945; 
	border-radius:5px;
	margin-left:0px; 
	margin-right:0px; 
	margin-top:5px; 
	margin-bottom:20px; 
} 
.puppyPilePrefs >div:first-of-type{ 
	background-color:#f2dc94; 
	color:#444; 
} 
.puppyPilePrefs >div{
	padding:3px 0px 3px 15px; 
}
.puppyPilePrefs >div:last-of-type{ 
	border-left:1px solid #CDCDCD; 
} 
.puppyPileActions{ 
	text-align:right; 
} 
.puppyPileMembersList{ 
	border:1px solid #b82a34; 
	border-radius:5px; 
	height:100%; 
}
.puppyPileMembersListBody{ 
	max-height:450px; 
	overflow-y: auto;
	padding-bottom:5px; 
	}
.puppyPileMemberHeader,
.puppyPileChatHeader{ 
	background-color:#DDD; 
	color:#444; 
	padding:5px 10px; 
	font-size:13px; 
	margin-bottom:5px; 
	border-top-right-radius:5px; 
	border-top-left-radius:5px; 
} 
.puppyPileMemberHeader{ 
	background-color:#e6959b; 
} 
.puppyPileChatHeader{ 
	background-color:#9adbb6; 
} 
.puppyPileMemberRow { 
	clear:both; 
	margin:2.5px 0px; 
	padding:2px 7px 0px 7px;
	color:#000; 
	position:relative; 
	display:block; 
	height:61px; 
	width:100%; 
} 
.puppyPileMemberRow:hover{ 
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23), 3px 0 6px rgba(0,0,0,0.16), 3px 0px 6px rgba(0,0,0,0.23);
	color:#000; 
} 
.puppyPileMemberRow.nohover:hover{ 
	box-shadow:none; 
} 
.puppyPileMemberRow.invited{ 
	opacity:0.8; 
	font-style:italic; 
	color:#999; 
} 
.puppyPileMemberRow .declined{ 
	text-decoration:line-through; 
	color:#999; 
} 
.puppyPileMemberRow img{ 
	display:inline-block; 
	margin-right:10px; 
	margin-bottom:10px; 
} 
.puppyPileMemberRow div{ 
	display:inline-block; 
} 
.puppyPileMemberRow div small{
	color:#999; 
	display:block; 
	padding:0px; 
} 
.puppyPileChat{ 
	border:1px solid #0d9348; 
	border-radius:5px; 
	height:100%; 
	position:relative; 
	min-height:450px; 
} 
.editPuppyPileMembers,
.editPuppyPilePrefs{ 
	border:1px solid #f0c945; 
	border-radius:10px; 
	margin-top:20px; 
	margin-bottom:20px; 
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); 
} 
.editPuppyPileMembers .header,
.editPuppyPilePrefs .header{ 
	padding-top:4px; 
	padding-bottom:4px; 
	padding-left:10px; 
	font-size:14px; 
	margin-bottom:10px; 
	border-top-right-radius:10px; 
	border-top-left-radius:10px; 
	background-color:#f0c945; 
} 
.editPuppyPileMemberRow > div:last-child{ 
	text-align:right; 
} 
.editPuppyPileMemberRow > div:last-child button{ 
	margin:0px 10px; 
} 
.editPuppyPilePrefs .col-lg-1{ 
	padding-left:0px; 
	padding-right:0px; 
	padding-top:29px; 
} 
.editPuppyPileMembers .buttonRow,
.editPuppyPilePrefs .buttonRow{ 
	text-align:right; 
	padding-bottom:10px; 
	padding-right:20px; 
} 
.editPuppyPileMembers .buttonRow{ 
	padding-top:35px; 
} 
.editPuppyPileMembers .rbt{ 
	margin-bottom:20px; 
}
.editPuppyPileMembers .buttonRow button:first-of-type,
.editPuppyPilePrefs .buttonRow button:first-of-type{ 
	margin-right:20px; 
} 
.editPuppyPileMembers .form-group{ 
	margin-bottom:auto; 
	margin-top:10px; 
} 

.editPuppyPileMembers .rook-checkbox{ 
	width:100%; 
} 
.chat_interface{
	position:absolute; 
	height: 98%; 
	top:0px; 
	width:100%; 
}
.chat_log{
	margin-top:35px; 
	max-height:350px; 
	overflow-y: scroll;
	padding-bottom:5px; 
} 
.chat_log .scroller{
	height:100%; 
	max-height:350px; 
} 
.chat_log .scroller > div{ 
	max-height:350px; 
} 
	
.chat_action{ 
	color:#666; 
	font-style:italic; 
	margin:10px 0px 10px 0px;
	font-size:12px; 
} 
.chat_message{
	margin:10px 0px 20px 0px;
} 
.chat_log .timestamp{ 
	color:#888; 
	font-size:12px; 
	font-style:normal; 
} 
.chat_message .thumbnail img{ 
	border-radius:25px; 
} 
.chat_message .name{ 
	font-weight:bold; 
	font-size:14px; 
	display:block; 
	margin-bottom:2px; 
} 
.chat_message .name .timestamp{ 
	font-weight:normal;
} 
.chat_log p{ 
	margin:0px; 
} 
.chat_entry{ 
	position:absolute;
	bottom:0px; 
	border:1px solid #0d9348; 
	background-color:#9adbb6; 
	border-radius:5px; 
	margin-left:15px; 
	width:96%; 
} 

.chat_entry > .row > div:first-of-type{ 
	padding-right:0px; 
} 

#newMessageEditor,.editor{ 
	width:100%; 
	border:1px solid #CCC; 

	margin-top:5px; 
	margin-bottom:5px; 
	background-color:#FFF; 
	padding:5px; 
} 
.editor p{ 
	margin:0px; 
} 
.submit{ 
	padding-top:6px; 
	text-align:center; 
} 
	

@media (max-width: 767px) { 
	.no-bottom-margin{
		margin-bottom:20px !important; 
	}

	.puppyPileChat{ 
		margin-top:15px;
		min-height:475px; 
	}
	.scroller{ padding:0px; margin:0px;  } 
	.thumbnail{ 
		padding:0px; 
		margin:0px; 
	} 
	.thumbnail img{ 
		width:25px; 
		height:25px; 
	} 
	.timestamp{ 
		display:block; 
	} 
	.chat_entry{ 
		margin-left:5px; 

	} 
	.chat_entry .col-12{ 
		padding:0px; 
	} 
	#newMessageEditor,.editor{ 
		width:96%; 
		margin-left:5px; 
	}
	.submit .btn{ 
		width:96% !important; 
		float:none !important; 
		
	} 
	
}
@media (min-width: 768px) and (max-width: 991px) {  
	.puppyPileMembersList{ 
		margin-top:15px; 
	} 
	.puppyPileChat{ 
		min-height:475px; 
	}
	.scroller{ padding:0px; margin:0px;  } 
	.thumbnail{ 
		padding:0px; 
		margin:0px; 
	} 
} 