.packListCard{ 
	width:18%; 
	float:left; 
	margin:10px; 
	min-height:410px; 
	position:relative; 
	z-index:1; 
  transition: box-shadow 0.3s ease-in-out;
} 
.packListCard:hover{
	  box-shadow: 0 2px 10px rgba(0,0,0,0.44);
}

.guestName{ 
	text-transform:uppercase; 
	font-family: 'Oswald', sans-serif;
} 

.packListCard .photo{ 
	position:relative; 
	z-index:10; 
	height:198px; 
	width:100%; 
} 
.packListCard .photo img{ 
	position:relative; 
	z-index:11; 
} 
.packListCard .photo .badges{ 
	position:absolute; 
	bottom:5px; 
	left:5px; 
	z-index:20;
	width:100%; 
} 
.packListCard .photo .badges .typebadge{ 
	text-transform:uppercase; 
	display:inline-block; 
	border-radius:5px; 
	padding:2px 5px;
	font-size:0.85rem;
	letter-spacing:1px; 
	font-weight:bold; 
	margin-right:3px; 
	color:white; 
	  box-shadow: 0 1px 3px rgba(0,0,0,0.2), 0 1px 2px rgba(0,0,0,0.44);
} 
.packListCard .photo .badges .typebadge.volunteer{
	background-color:#0d9348; 
} 
.packListCard .photo .badges .typebadge.director{
	background-color:#b82a34; 
} 
.packListCard .photo .badges .typebadge.job{
	background-color:#28377d; 
} 
.packListCard .cardcontent{ 
	overflow:hidden;
	height:125px; 
	position:relative; 
} 
.packListCard .cardcontent a,
.packListCard .cardcontent a:hover{ 
	color:#000000 !important;
	text-decoration:none !important;
}
	
.packListCard .cardcontent:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 1.2em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
}
.packListCard .card-title{
	margin-bottom:5px; 
	color:#000000; 
	text-decoration:none; 
} 
.packListCard .card-body{ 
	padding:10px; 
} 
.packListCard .bio{ 
	font-size:0.85rem; 
	line-height:1.35em !important;
} 
.packListCard .links{ 
	position:absolute;
	bottom:5px; 
	width:90%; 
} 
.packListCard .links .btn { 
	margin-right:10px; 
} 
.packListCard .links .btn svg{ 
	margin-right:0px; 
} 
.viewprofilelink{ 
	margin-top:7px; 
} 
.viewprofilelink .btn{
	  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 
	
} 


@media (max-width: 767px) { 

	.packListCard{ 
		width:100%; 
		min-height:auto; 
		margin-left:0px; 
		flex-direction:row;
	} 
	.packListCard .photo{ 
		width:25%; 
		height:auto; 
		float:left; 
	} 
	.packListCard .card-body{ 
		max-width:70%; 
	} 
	.packListCard .cardcontent{ 
		height:auto;
		max-height:105px; 
	} 
	.packListCard .bio{ 
		margin-bottom:0px !important;
	}
	
	.packListCard .photo .badges{ 
		display:none; 
	} 
	
	.viewprofilelink .btn{
		width: auto; 
		padding-left:10px; 
		padding-right:10px;
	}
	
	.packListCard .links{ 
		display:none; 
	} 
}
@media (min-width: 768px) and (max-width: 991px) { 
	
	.packListCard{ 
		width:30%; 
	}
}