.nav-tabs{ 
	border-bottom:none; 
	margin-bottom:30px; 
} 
.nav-tabs .nav-link{ 
	background:transparent; 
	border:1px solid #0d9348; 
	color:#0d9348; 
	border-radius:10px; 
	margin-right:1rem; 
} 

.nav-tabs .nav-link:hover{ 
	border-color: #0d9348; 
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	
}
.nav-tabs .nav-link.active{ 
	background-color:#0d9348; 
	color:#FFFFFF; 
	border:0px; 
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
} 


.accordion .card-header{ 
	background-color:#EFEFEF !important; 
	color:#000 !important; 
	border-radius:0px !important; 
	border:none !important; 
} 

.accordion .card-header.unread{
	font-weight:bold; 
}  
.accordion .announcement .card-header{ 
	background-color:#9adbb6 !important;
} 
.accordion .priority-1 .card-header{ 
	background-color:#f2dc94 !important;
} 
.accordion .priority-2 .card-header{ 
	background-color:#e6959b !important;
} 
.accordion .card-header .tofrom,
.accordion .card-header .subject{ 
	text-overflow:ellipsis; 
	white-space: nowrap;
  overflow: hidden;
} 
.accordion .card-header .tofrom img.directors{ 
	margin-right:10px; 
	vertical-align:middle; 
}
.accordion .card-header .tofrom img.guest{ 
	border-radius:15px; 
	margin-right:10px; 
	vertical-align:middle; 
} 
.accordion .card-header .tofrom.col-lg-2{ 
	flex:0 0 21.33334%; 
	max-width:21.33334%; 
} 

.accordion .card-header .subject.col-lg-6{
	padding-top:1px; 
} 
.accordion .announcement .subject small{ 
	display:inline-block; 
	background-color:#0d9348; 
	border-radius:3px; 
	padding:2px 4px; 
	margin-right:5px; 
	color:#FFFFFF; 
	vertical-align:middle;
	text-transform:uppercase; 
	font-size:11px; 
} 
.accordion .priority-1 .subject small{
	background-color:#cb6a30; 
	margin-top:-2px; 
} 
.accordion .priority-1 .subject svg{
	color:#cb6a30; 
	margin-right:3px; 
	margin-top:1px; 
	margin-bottom:-1px; 
} 
.accordion .priority-2 .subject small{
	background-color:#b82a34; 
	margin-top:-2px; 
} 
.accordion .priority-2 .subject svg{
	color:#b82a34; 
	margin-right:3px; 
	margin-top:1px; 
	margin-bottom:-1px; 
} 
.accordion .card-header .date.col-lg-2{
	font-size:.8rem; 
	text-align:right;
	padding-top:4px; 
}
.accordion .card-header .star.col-lg-2{ 
	flex:0 0 12%; 
	max-width:12%; 
	padding-left:0px; 
	padding-right:0px; 
} 
.accordion .card-header .star{ 
	color:#0d9348; 
} 
.accordion .card-header .star small{ 
	text-transform:uppercase; 
	font-weight:bold; 
	padding:2px 2px 2px 5px; 
	border-radius:2px; 
	color:#0d9348; 
} 
.messagethread .message{ 
	border:1px solid #BBB; 
	padding:10px; 
	border-radius:10px; 
	margin-top:10px; 
	margin-bottom:20px; 
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
} 
.messagethread .message h6{ 
	margin-bottom:15px; 
} 
.messagethread .message .envelope .icon{ 
	width:50px; 
	text-align:center; 
}
.messagethread .message .envelope .icon img.guest{ 
	border-radius:25px; 
} 
.messagethread .message .envelope .detail{ 
	font-size:12px; 
	padding-left:0px; 
} 
.messagethread .message .body{ 
	margin-top:10px; 
} 

.sendToDirectorsLink{ 
	display:block; 
	padding-top:5px; 
} 
.messageReplyEditor .tox-tinymce{ 
	margin-bottom:15px; 
/* 	border:0px;  */
} 
.messageReplyEditor .tox-statusbar__branding{ 
	display:none; 
} 
.messageReplyEditor .tox-statusbar__resize-handle{ 
	padding-right:2px; 
	padding-bottom:2px; 
}
.messageReplyEditor .tox-statusbar{ 
	background:transparent; 
} 
.messageReplyEditor .tox-toolbar__primary{ 
	background:transparent; 
} 


@media (max-width: 576px) { 

	.accordion .card-header{ 
		padding:10px; 
	} 
	.accordion .card-header .date.col-lg-2{
		text-align:left; 
	} 
	
	.accordion .card-header .tofrom.col-lg-2{ 
		flex:0 0 100%; 
		max-width:100%; 
	} 
	
	.accordion .card-header .star{ 
		text-align:right; 
	} 
	.messagethread{ 
		padding:10px; 
	} 
	.accordion .card-header .star small{ 
		display:none; 
	}
	.messagethread .message .envelope .icon{ 
		display:none; 
	}
	
	.messagethread .message .envelope .detail{
		padding-left:15px !important;
	} 

	.messageReplyEditor,.messageReplySend{ 
		padding-left:0px; 
		padding-right:0px; 
	} 
	.messagesAccordion{ 
		padding:0px; 
	} 
		
} 
@media (min-width: 577px) and (max-width: 767px) { 
	.accordion .card-header .tofrom.col-lg-2{ 
		flex:0 0 50%; 
		max-width:50%; 
	} 
	.accordion .card-header .star small{ 
		display:none; 
	}
}
@media (min-width: 768px) and (max-width: 991px) {  
	.accordion .card-header .tofrom.col-lg-2{ 
		flex:0 0 60%; 
		max-width:60%; 
	} 
	.accordion .card-header .star small{ 
		text-wrap:nowrap
	} 
}