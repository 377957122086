/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400;500;700&family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

html,body{ 
	background-color:#EAE3D6; 
} 


/****** 
PITM BRAND COLORS 

Blue 			#28377d
Dark Blue #2d2d42
Yellow 		#f0c945
Red 			#b82a34
Gold 			#b6af2b
Purple 		#9239a2
Green 		#0d9348
Orange 		#cb6a30
Gray 			#6d6d6d

********/ 

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1 0 auto;
	width:100%; 
}

footer.footer {
  flex-shrink: 0;
  text-align: center;
  background-color: #0d9948;
  color: white;
	font-size:.8rem; 
	border-top-left-radius:20px; 
	border-top-right-radius:20px; 
}
footer.footer a{ 
	color:#FFF; 
	text-decoration:underline;
} 

h1,h2,h3,h4,h5{ 
	text-transform:uppercase; 
	font-family: 'Oswald', sans-serif;
} 
h6{ 
	font-size:20px; 
	font-weight:bold; 
} 
h1{ 
	color:#28377d; 
	text-shadow: 2px 2px 1px rgba(255,255,255,.7);
	margin-bottom:20px; 
} 
.row .card:first-of-type{ 
	margin-top:0px; 
} 
.row .card.force-top-margin:first-of-type{ 
	margin-top:30px; 
} 
table.terms{ 
	margin-bottom:30px; 
} 
table.terms td:first-of-type{ 
	width:250px; 
} 
table.terms td{ 
	vertical-align:top; 
	border-bottom:1px solid #CCC; 
	padding-top:5px;
	padding-bottom:5px; 
} 